.App {
  text-align: center;
  background-color: #000000;
}

.App-logo {
  max-width: 80%;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    max-height: 100px;
  }
}

@media (max-width: 932px) {
  .operation {
    min-width: 80vw;
  }
  .imagesNone {
    display: none;
  }
  .barGraph {
    display: none;
  }
  .upperBox {
    width: 80vw !important;
  }
  .lineGraph {
    display: none;
  }
}

@media (max-width: 600px) {
  .headingText {
    font-size: 1.75rem !important;
  }
}

@media (max-width: 600px) {
  /* .container {
    display: block !important;
  } */
  /* input {
    text-align: center !important;
  } */
  .searchButton {
    width: 5rem !important;
  }
  p {
    font-size: 0.75rem !important;
  }
  .textSmall {
    font-size: 0.75rem !important;
  }
}

.App-header {
  padding: 4rem 0rem;
  background-color: #000000;
  min-height: 100vh;
}

@media (min-width: 1200px) {
  .grey {
    max-width: 1200px;
  }
  .black {
    max-width: 1200px;
  }
}
.grey {
  background-color: #1c1c1c;
  border: solid 2pt #666666;
  opacity: 100%;
  margin: auto;
}
.black {
  margin: auto;
}

.new_block {
  position: relative;
  padding-bottom: 4rem;
}

.padding_box {
  padding: 2rem;
}

.App-link {
  color: #61dafb;
}

.bottom-right {
  height: 80%;
  position: absolute;
  /* align-self: flex-end; */
  right: 0px;
  bottom: 0px;
}

/* .padding_box_writing {
  padding-top: 4rem;
  padding-bottom: 2rem; */
/* } */

.boxDisplay {
  max-height: 225px;
  margin: auto;
}

p {
  font-family: "purista-web";
  color: white;
  margin-block-start: 0em;

  font-size: 1.5rem;
  margin-block-end: 0;
}
.orange_text {
  color: #ff0000;
  text-shadow: 0 0 7px #ff000099;
}

.bottom {
  position: absolute;
  max-height: 40px;
  width: 160px;
  left: calc(50% - 80px);
  margin: auto;
  bottom: -12.5px;
}

input {
  font-family: "purista-web";
  padding: 2rem;
  background: none;
  border: 0;
  font-size: 1.5rem !important;
  color: white;
  left: 0;
  width: 90%;
  font-weight: 400 !important;
  text-align: left;

  /* height: 110px; */
}

.searchBox {
  position: relative;
  border: solid 2pt #666666;
  margin: auto;
  width: 725px;
  background: linear-gradient(
    #ff000050 0%,
    #ff000025 5%,
    #ff000008 50%,
    #ff000025 95%,
    #ff000050 100%
  );
  color: white;
  font-size: 2.5rem;
  height: 110px;
}
.boxHeading {
  position: relative;

  top: 35px;
  border: solid 2pt #666666;
  margin: auto;
  padding: 0rem;
  background: linear-gradient(
    #ff000050 0%,
    #ff000025 5%,
    #ff000008 50%,
    #ff000025 95%,
    #ff000050 100%
  );
  color: white;
  font-size: 2.5rem;
}
input:focus {
  outline: none;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}

.popup {
  padding: 20px;
  border-radius: 10px;
  background: #141414;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
}

.colourDiff {
  background: #3d2323;
}
.no-scroll {
  overflow: hidden;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
}

.blurred-background {
  filter: blur(5px);
  pointer-events: none;
}

.upperBox {
  margin: 4rem auto;
  width: 60%;
  position: relative;
}

.container {
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  grid-template-rows: auto;
}

.left {
  grid-column: 1 / 2;
  display: flex;
  align-items: center;
}

.right {
  grid-column: 2 / 3;
}

.searchButton {
  background: #ff0000;
  border: none;
  margin: 0.5rem;
  height: 3rem;
  width: 10rem;
}

.leaderContainer {
  display: flex;
  width: 90%;
  margin: auto;
}

.leftLeader {
  flex: 1;
  display: flex;
  justify-content: center;
}

.rightLeader {
  flex: 1;
  display: flex;
  justify-content: center;
}
@media (max-width: 932px) {
  .leaderContainer {
    display: block;
  }
  .containerNames {
    max-width: 100%;
  }
  .highScore {
    width: 100%;
  }
}

body {
  background: #000000;
}

.containerNames {
  padding: 0.5rem 0;
  display: flex;
}

.small-part {
  flex-basis: 50px;
  flex-shrink: 0;
  margin-right: 1rem;
}

.large-part {
  flex-basis: calc(50% - 25px - 0.5rem);
  flex-shrink: 0;
}

.numberBox {
  position: relative;
  border: solid 2pt #ff0000;
  margin: auto;
  padding: 0rem;
  background: linear-gradient(
    #ff000050 0%,
    #ff000025 5%,
    #ff000008 50%,
    #ff000025 95%,
    #ff000050 100%
  );
  color: white;
  font-size: 2.5rem;
}

.whiteGlow {
  text-shadow: 0 0 7px #ffffff99;
}
.textBox {
  padding: 0.25rem;
}

.textBoxLeader {
  position: relative;
  border: solid 2pt #666666;
  margin: auto;
  padding: 0rem;
  background: linear-gradient(
    #ff000050 0%,
    #ff000025 5%,
    #ff000008 50%,
    #ff000025 95%,
    #ff000050 100%
  );
  color: white;
  font-size: 2.5rem;
}

.lineGraph {
  height: 70%;
  position: absolute;
  left: -150px;
  top: 30px;
}

.barGraph {
  height: 70%;
  position: absolute;
  right: -150px;
  top: 30px;
}

.headingText {
  margin: auto;
  font-size: 2.25rem;
  font-weight: bold;
  padding: 1rem 0;
}
.semibold {
  font-weight: 600;
}

.bodyText {
  margin: auto;
  font-size: 1.15rem;
  padding: 0 1rem 1rem 1rem;
}

@media (max-width: 600px) {
  .clickMore {
    display: none;
  }
  .squares {
    display: none;
  }
  .bodyText {
    font-size: 0.75rem;
  }
}
